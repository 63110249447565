import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { booleanOptionsForRadioGroup } from '../../../../../features/forms/helpers/random-form-helpers';
import Input from '../../../../forms/components/Input';
import MultiSelect from '../../../../forms/components/MultiSelect';
import {
  searchPlaceOfBirth,
  selectPlaceOfBirthSearchResults,
} from '../../../../forms/components/place-of-birth/slices/placeOfBirthSlice';
import RadioButtonsCustom from '../../../../forms/components/RadioButtonsCustom';
import SearchPortsDropdown from '../../../../forms/components/search-ports-dropdown/components/SearchPortsDropdown';
import { removeNullUndefined } from '../../../../global/utils/cleanEmptyData';
import { countryListAllIsoData } from '../../../../global/utils/countriesList';
import { validateSchema } from '../../../../global/utils/validateForms';
import Button from '../../../../layout/components/Button';
import ConfirmationModal from '../../../../layout/components/ConfirmationModal';
import Modal from '../../../../layout/components/Modal';
import ProgressBar from '../../../../layout/components/ProgressBar';
import Table from '../../../../tables/components/Table';
import chevronLeft from '../../../../tables/styles/images/chevronLeft.svg';
import chevronRight from '../../../../tables/styles/images/chevronRight.svg';
import plusIcon from '../../../../tables/styles/images/plusIcon.svg';
import {
  closeDangrousGoodsDropdown,
  searchDangerousGoods,
  selectDangerousGoods,
  showDangerousGoodsModal,
  updateDangerousGoods,
} from '../../../slices/portCallSlice';
import ConfirmDeleteModal from '../../crew/components/ConfirmDeleteModal';
import { searchForPort } from '../../health/slices/portCallSlice';
import '../styles/dangerous-goods-table.scss';
import {
  initialFormDangerousGoodsValues,
  StowageLevelList,
  StowagePositionList,
  TableColumns,
  TypeOfCargoList,
} from './config';

const marinePollutantOptions = booleanOptionsForRadioGroup();

function DangerousGoodsTable() {
  const dispatch = useDispatch();
  const dangerousGoods = useSelector(selectDangerousGoods);
  const shouldShowDangerousGoodsDropdown = dangerousGoods.ui.showDropdown;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [stepForm, setStepForm] = useState(1);
  const [isTanker, setIsTanker] = useState(false);
  const [initialValues, setInitialValues] = useState(initialFormDangerousGoodsValues);
  const [shipperCitiesOptions, setShipperCitiesOptions] = useState([]);
  const [receiverCitiesOptions, setReceiverCitiesOptions] = useState([]);
  const shipperCitiesResults = useSelector((state) => selectPlaceOfBirthSearchResults(state, 'shipperCity')) || [];
  const receiverCitiesResults = useSelector((state) => selectPlaceOfBirthSearchResults(state, 'receiverCity')) || [];

  const handleCitiesUnChanged = _.debounce((value, country, name) => {
    if (value) {
      dispatch(
        searchPlaceOfBirth({
          dropdownID: name,
          value: value,
          countryCode: country,
        })
      );
    }
  }, 500);

  const handleCitiesUnChangeDebounced = (value, country, name) => {
    if (value) {
      dispatch(
        searchPlaceOfBirth({
          dropdownID: name,
          value: value,
          countryCode: country,
        })
      );
    }
    if (handleCitiesUnChanged.cancel) {
      handleCitiesUnChanged.cancel(); // If there is an existing debounce, cancel it
    }

    handleCitiesUnChanged(value, country); // Start a new debounce with the latest value
  };

  useEffect(() => {
    if (shipperCitiesResults?.length < 1) return;

    let options = [{ label: '-', value: '' }];
    if (shipperCitiesResults) {
      options.push(...shipperCitiesResults);
    }

    setShipperCitiesOptions(options);
  }, [shipperCitiesResults]);

  useEffect(() => {
    if (receiverCitiesResults?.length < 1) return;

    let options = [{ label: '-', value: '' }];
    if (receiverCitiesResults) {
      options.push(...receiverCitiesResults);
    }

    setReceiverCitiesOptions(options);
  }, [receiverCitiesResults]);

  useEffect(() => {
    if (!isModalOpen) {
      setReceiverCitiesOptions([]);
      setShipperCitiesOptions([]);
    }
  }, [isModalOpen]);

  const handleDangerousGoodsUnChanged = _.debounce((value) => {
    if (value) {
      dispatch(
        searchDangerousGoods({
          un: value,
        })
      );
    }
  }, 500);

  const handleDangerousGoodsUnChangeDebounced = ({ target: { value } }) => {
    if (handleDangerousGoodsUnChanged.cancel) {
      handleDangerousGoodsUnChanged.cancel(); // If there is an existing debounce, cancel it
    }

    handleDangerousGoodsUnChanged(value); // Start a new debounce with the latest value
  };

  const dangerousGoodsSearchResults = dangerousGoods.dangerousGoodsSearchResults;
  const shouldShowLoadingInDangerousGoodsDropdown = dangerousGoods.dangerousGoodsSearchResultsLoading;
  const dangerousGoodsDropdownOptions = dangerousGoodsSearchResults;

  const validationSchema = Yup.object().shape({
    typeOfCargo: Yup.string().required('*This field is required'),
    un: Yup.string().required('*This field is required'),
    properShippingName: Yup.string().when('un', {
      is: (value) => {
        return !!value && value.trim().length > 0;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    class: Yup.string().when('un', {
      is: (value) => {
        return !!value && value.trim().length > 0;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    marinePollutant: Yup.string().required('*This field is required'),
    netWeight: Yup.string().required('*This field is required'),
    ems: Yup.string()
      .matches(/^F-[A-J]{1}S-[A-Z]{1}$/, 'Invalid format')
      .nullable(),
    loadPort: Yup.string().required('*This field is required'),
    dischargePort: Yup.string().required('*This field is required'),

    receiverName: Yup.string().when('unknownReceiver', {
      is: (value) => {
        return !!!value;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    receiverCountry: Yup.string().when('unknownReceiver', {
      is: (value) => {
        return !!!value;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    receiverCity: Yup.string().when('unknownReceiver', {
      is: (value) => {
        return !!!value;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    receiverAddress: Yup.string().when('unknownReceiver', {
      is: (value) => {
        return !!!value;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    receiverVatNumber: Yup.string().when('unknownReceiver', {
      is: (value) => {
        return !!!value;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    receiverPhoneNumber: Yup.string().when('unknownReceiver', {
      is: (value) => {
        return !!!value;
      },
      then: () => Yup.string().required('*This field is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    shipperName: Yup.string().required('*This field is required'),
    shipperCountry: Yup.string().required('*This field is required'),
    shipperCity: Yup.mixed().test(
      'shipperCity',
      '*This field is required',
      (value) => typeof value === 'string' || (typeof value === 'object' && value !== null)
    ),
    shipperAddress: Yup.string().required('*This field is required'),
    shipperVatNumber: Yup.string().required('*This field is required'),
    shipperPhoneNumber: Yup.string().required('*This field is required'),
    tankHoldNumber: Yup.string()
      .matches(/^[A-Za-z0-9,]{0,25}$/g, 'Invalid format')
      .nullable(),
  });

  const formatFormValuesForApi = (values) => {
    return removeNullUndefined({
      ...values,
      tankHoldNumber: null,
      un: values?.un,
      packingGroup: values?.packingGroup,
      class: values?.class,
      loadPort: values?.loadPort?.unlocode || values?.loadPort?.value || values?.loadPort,
      dischargePort: values?.dischargePort?.unlocode || values?.dischargePort?.value || values?.dischargePort,
      properShippingName: values?.properShippingName,
      stowagePositionHold: values?.typeOfCargo !== 'Liquid bulk' ? values?.tankHoldNumber : null,
      stowageTankerNumber: values?.typeOfCargo === 'Liquid bulk' ? values?.tankHoldNumber : null,
      stowagePosition: values?.typeOfCargo !== 'Liquid bulk' ? values?.stowagePosition : null,
      stowageLevel: values?.typeOfCargo !== 'Liquid bulk' ? values?.stowageLevel : null,
      shipper: {
        companyName: values?.shipperName,
        address: values?.shipperAddress,
        country: values?.shipperCountry || values?.shipperCity?.value?.slice(0, 2) || null,
        city: values?.shipperCity?.value ? values?.shipperCity?.value : values?.shipperCity,
        telephone: values?.shipperPhoneNumber,
        vatNumber: values?.shipperVatNumber,
      },
      receiver: values?.unknownReceiver
        ? {
            companyName: null,
            address: null,
            country: null,
            city: null,
            telephone: null,
            vatNumber: null,
            unknown: true,
          }
        : {
            companyName: values?.receiverName,
            address: values?.receiverAddress,
            country: values?.receiverCountry || values?.receiverCountry?.value?.slice(0, 2) || null,
            city: values?.receiverCity?.value ? values?.receiverCity?.value : values?.receiverCity,
            telephone: values?.receiverPhoneNumber,
            vatNumber: values?.receiverVatNumber,
            unknown: false,
          },
    });
  };

  const formatFormValuesFromApi = async (values) => {
    const isReceiverUnknown = values?.receiver?.unknown ?? false;
    const loadPort = values.loadPort ? await dispatch(searchForPort({ unlocode: values.loadPort })) : null;
    const dischargePort = values.dischargePort
      ? await dispatch(searchForPort({ unlocode: values.dischargePort }))
      : null;
    const shipperCity = values?.shipper?.city
      ? await dispatch(
          searchPlaceOfBirth({
            location: values?.shipper?.city.slice(2),
            countryCode: values?.shipper?.city.slice(0, 2),
          })
        )
      : null;

    let receiverCity = null;
    if (!isReceiverUnknown) {
      receiverCity = values?.receiver?.city
        ? await dispatch(
            searchPlaceOfBirth({
              location: values?.receiver?.city.slice(2),
              countryCode: values?.receiver?.city.slice(0, 2),
            })
          )
        : null;
    }

    return removeNullUndefined({
      ...values,
      unknownReceiver: isReceiverUnknown,
      receiverName: isReceiverUnknown ? '' : values?.receiver?.companyName,
      receiverCountry: isReceiverUnknown
        ? ''
        : countryListAllIsoData?.find((el) => el?.value === values?.receiver?.country)?.value,
      receiverCity: isReceiverUnknown ? '' : receiverCity?.payload?.data[0],
      receiverAddress: isReceiverUnknown ? '' : values?.receiver?.address,
      receiverVatNumber: isReceiverUnknown ? '' : values?.receiver?.vatNumber,
      receiverPhoneNumber: isReceiverUnknown ? '' : values?.receiver?.telephone,
      shipperName: values?.shipper?.companyName,
      shipperCountry: countryListAllIsoData?.find((el) => el?.value === values?.shipper?.country)?.value || null,
      shipperCity: shipperCity?.payload?.data[0] || null,
      shipperAddress: values?.shipper?.address,
      shipperVatNumber: values?.shipper?.vatNumber,
      shipperPhoneNumber: values?.shipper?.telephone,
      tankHoldNumber: values?.stowageTankerNumber || values?.stowagePositionHold,
      typeOfCargo: TypeOfCargoList?.find((el) => el.value === values?.typeOfCargo)?.value || null,
      stowagePosition: StowagePositionList?.find((el) => el.value === values?.stowagePosition)?.value || null,
      stowageLevel: StowageLevelList?.find((el) => el.value === values?.stowageLevel)?.value || null,
      grossMass: values?.massOrVolume?.split(' ')[0] || null,
      netVolume: values?.massOrVolume?.split(' ')[1] || null,
      receiver: undefined,
      shipper: undefined,
      loadPort: loadPort?.payload[0] || null,
      dischargePort: dischargePort?.payload[0] || null,
    });
  };

  const handleSubmit = async (values, _) => {
    const formattedValues = formatFormValuesForApi(values);
    if (isEditingRow) {
      const newMembers = dangerousGoods.dangerousGoodsList.map((member, i) => {
        if (i === editingRow) {
          return formattedValues;
        }
        return removeNullUndefined(member);
      });

      dispatch(
        updateDangerousGoods({
          dangerousGoods: newMembers,
        })
      );
    } else {
      dispatch(
        updateDangerousGoods({
          dangerousGoods: dangerousGoods.dangerousGoodsList
            .map((element) => removeNullUndefined(element))
            .concat(formattedValues),
        })
      );
    }
    setEditingRow(null);
    setIsModalOpen(false);
    setInitialValues(initialFormDangerousGoodsValues);
    setTimeout(() => setStepForm(1), 400);
  };

  const [isEditingTable, setIsEditingTable] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  let isEditingRow = editingRow !== null;

  useEffect(() => {
    isEditingRow = editingRow !== null;
    const row = isEditingRow ? dangerousGoods.dangerousGoodsList[editingRow] : null;
    if (row)
      formatFormValuesFromApi(row).then((result) => {
        setIsTanker(result?.typeOfCargo === 'Liquid bulk');
        setInitialValues(result);
      });
  }, [editingRow]);

  const [confirmDeleteMemberModalData, setConfirmDeleteMemberModalData] = useState({
    index: null,
    showModal: false,
  });
  const [showConfirmDeleteAllMembersModal, setShowConfirmDeleteAllMembersModal] = useState(false);

  const handleDangerousGoodClicked = function (dangerousGood) {
    dispatch(closeDangrousGoodsDropdown());

    setInitialValues({
      ...initialValues,
      class: dangerousGood?.data?.class || '',
      un: dangerousGood?.data?.un || '',
      properShippingName: dangerousGood?.data?.name || '',
      packingGroup: dangerousGood?.data?.packingGroup || '',
    });
  };

  const convertDataForTable = (data) => {
    return (
      data?.map((el, _) => ({
        ...el,
        stowagePosition: StowagePositionList.find((element) => element.value === el.stowagePosition)?.label || '',
        subsidiaryRisk: `${el?.marinePollutant ? 'P' : ''} ${el?.subsidiaryRisk || ''} ${el?.subsidiaryRisk2 || ''} ${
          el?.subsidiaryRisk3 || ''
        }`,
        massOrVolume: `${el?.netWeight ? 'M:' + el?.netWeight : ''} ${el?.volume ? 'V:' + el?.volume : ''}`,
        bookingNumber: `${el?.bookingNumber ? el?.bookingNumber : ''} ${
          el?.billOfLadingNumber ? el?.billOfLadingNumber : ''
        }`,
      })) || []
    );
  };

  return (
    <div className='dangerous-goods-table'>
      <Table
        title={'Dangerous goods manifest'}
        addButtonText={'Add dangerous goods'}
        data={convertDataForTable(dangerousGoods.dangerousGoodsList)}
        columns={TableColumns}
        editingMode={isEditingTable}
        setEditingMode={setIsEditingTable}
        setIsAddModalOpen={setIsModalOpen}
        setIsDeleteModalOpen={setShowConfirmDeleteAllMembersModal}
        onEditButtonClick={(rowIndex) => {
          setEditingRow(rowIndex);
          setIsModalOpen(true);
        }}
        onDeleteButtonClick={(rowIndex) => {
          setConfirmDeleteMemberModalData({
            showModal: true,
            index: rowIndex,
          });
        }}
      />
      <Modal
        className={`add-dangerous-good-modal`}
        title={'Dangerous goods manifest'}
        onClick={() => {
          dispatch(closeDangrousGoodsDropdown());
        }}
        showModal={isModalOpen}
        onCloseModal={() => {
          dispatch(closeDangrousGoodsDropdown());
          setIsModalOpen(false);
          setEditingRow(null);
          setInitialValues(initialFormDangerousGoodsValues);
          setTimeout(() => setStepForm(1), 400);
        }}>
        <ConfirmationModal
          showModal={isConfirmationModalOpen}
          handleCloseModal={setIsConfirmationModalOpen}
          willDisableSave={formValues}
          onAcceptModal={() => {
            setIsConfirmationModalOpen(false);
            setIsModalOpen(false);
            handleSubmit(formValues);
          }}
          className=''
        />
        <div className='add-dangerous-good-modal__content'>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validate={(values) => {
              values = {
                ...values,
                loadPort: values?.loadPort?.unlocode || values?.loadPort?.value || values?.loadPort,
                dischargePort: values?.dischargePort?.unlocode || values?.dischargePort?.value || values?.dischargePort,
                ems: values?.ems ? values?.ems : null,
              };
              const errors = validateSchema(
                values,
                validationSchema,
                setFormValues,
                setIsConfirmationModalOpen,
                setIsModalOpen
              );
              if (errors?.ems === 'Invalid format') setStepForm(1);
              if (errors?.tankHoldNumber === 'Invalid format') setStepForm(1);
              return errors;
            }}>
            {({ values, setFieldValue, errors, touched }) => (
              <Form className='add-dangerous-good-form'>
                <ProgressBar percentage={(stepForm / 2) * 100} pageNumber={stepForm} totalPages={2} />
                {stepForm === 1 && (
                  <>
                    <div className='add-dangerous-good-form__row'>
                      <MultiSelect
                        name='typeOfCargo'
                        options={TypeOfCargoList}
                        isMulti={false}
                        onChange={(value) => setIsTanker(value.value === 'Liquid bulk')}
                        label='Type of cargo*'
                        placeholder='Select one'
                      />
                      <MultiSelect
                        name='stowagePosition'
                        options={StowagePositionList}
                        isMulti={false}
                        disabled={isTanker}
                        label='Stowage position'
                        placeholder='Select one'
                      />
                      <MultiSelect
                        name='stowageLevel'
                        options={StowageLevelList}
                        isMulti={false}
                        disabled={isTanker}
                        label='Stowage level'
                        placeholder='Select one'
                      />
                      <Input name='tankHoldNumber' placeholder='Ex: 1S,2S,3S' label={'Tank or hold number'} />
                    </div>
                    <hr />
                    <div className='add-dangerous-good-form__row'>
                      <div className='un-container'>
                        <Input
                          name='un'
                          onClick={(e) => {
                            if (dangerousGoodsSearchResults.length) {
                              e.stopPropagation();
                              dispatch(showDangerousGoodsModal());
                            }
                          }}
                          onChange={handleDangerousGoodsUnChangeDebounced}
                          placeholder='UN Number'
                          label='UN Number*'
                        />
                        {shouldShowDangerousGoodsDropdown && (
                          <div className='list'>
                            {shouldShowLoadingInDangerousGoodsDropdown && 'Loading..'}
                            {dangerousGoodsSearchResults.length === 0 && (
                              <span className='un__empty-case'>Didn't find any dangerous goods with that UN code</span>
                            )}
                            {dangerousGoodsDropdownOptions.map((dangerousGood) => {
                              return (
                                <div className='list__item' onClick={() => handleDangerousGoodClicked(dangerousGood)}>
                                  {dangerousGood.label}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <Input
                        name='packingGroup'
                        disabled={!values?.un}
                        label='Packing group'
                        placeholder='Packing group'
                      />
                      <Input
                        name='properShippingName'
                        disabled={!values?.un}
                        label='Proper shipping name / Techincal specifications*'
                        placeholder='Proper shipping name / Techincal specifications'
                      />
                      <Input name='class' disabled={!values?.un} label='Class*' placeholder='Class' />
                    </div>
                    <div className='add-dangerous-good-form__row'>
                      <Input name='subsidiaryRisk' placeholder='Type One' label='Subsidiary Risk 1 (if any)' />
                      <Input name='subsidiaryRisk2' placeholder='Type One' label='Subsidiary Risk 2 (if any)' />
                      <RadioButtonsCustom
                        name='marinePollutant'
                        label='Marine Pollutant*'
                        options={marinePollutantOptions}
                      />
                      <Input
                        name='flashPoint'
                        placeholder='Flash Point (&deg;C)'
                        label='Flash Point (&deg;C)'
                        type={'number'}
                        min={0}
                      />
                    </div>
                    <div className='add-dangerous-good-form__row'>
                      <Input
                        name='netWeight'
                        placeholder='Net Mass (Kg)'
                        label='Net Mass (Kg)*'
                        type={'number'}
                        min={0}
                      />
                      <Input
                        name='grossWeight'
                        placeholder='Gross mass (Kg)'
                        label='Gross mass (Kg)'
                        type={'number'}
                        min={0}
                      />
                      <Input
                        name='volume'
                        placeholder='Net Volume (m3)'
                        label='Net Volume (m3)'
                        type={'number'}
                        min={0}
                      />
                      <Input name='ems' placeholder='Ex: F-JS-K' label='EmS' />
                    </div>
                    <div className='add-dangerous-good-form__row'>
                      <Input name='specialProvisions' placeholder='Special Provisions' label='Special Provisions' />
                      <Input
                        name='packingInstructions'
                        placeholder='Packing Instructions'
                        label='Packing Instructions'
                      />
                      <Input
                        name='additionalInformation'
                        placeholder='Addition information'
                        label='Addition information (if any)'
                      />
                    </div>
                    <div className='add-dangerous-good__form-submission-btns'>
                      <Button
                        className='discard-btn'
                        onClick={() => {
                          setIsModalOpen(false);
                          setTimeout(() => setEditingRow(null), 300);
                        }}>
                        Discard
                      </Button>
                      <Button
                        iconRight={chevronRight}
                        onClick={() => {
                          setStepForm(2);
                        }}>
                        Next
                      </Button>
                    </div>
                  </>
                )}
                {stepForm === 2 && (
                  <>
                    <div className='add-dangerous-good-form__row2'>
                      <Input name='bookingNumber' placeholder='Booking number' label='Booking number' />
                      <Input name='billOfLadingNumber' placeholder='B/L number' label='B/L number' />
                      <div className='un-container'>
                        <SearchPortsDropdown
                          label='Port of loading*'
                          name='loadPort'
                          dropdownID='loadPort'
                          placeholder='Select One'
                        />
                      </div>
                      <div className='un-container'>
                        <SearchPortsDropdown
                          label='Port of discharge*'
                          name='dischargePort'
                          dropdownID='dischargePort'
                          placeholder='Select One'
                        />
                      </div>
                    </div>
                    <hr />
                    <div className='add-dangerous-good-form__row2'>
                      <Input
                        name='receiverName'
                        placeholder={values.unknownReceiver ? 'Unknown' : "Receiver's name"}
                        label={values.unknownReceiver ? "Receiver's name" : "Receiver's name*"}
                        disabled={values.unknownReceiver}
                      />
                      {values.unknownReceiver ? (
                        <MultiSelect
                          name='receiverCountry'
                          options={[]}
                          isMulti={false}
                          placeholder=''
                          label="Receiver's country"
                          disabled={true}
                        />
                      ) : (
                        <MultiSelect
                          options={countryListAllIsoData}
                          isMulti={false}
                          name='receiverCountry'
                          placeholder="Receiver's country"
                          label="Receiver's country*"
                        />
                      )}
                      {values.unknownReceiver ? (
                        <MultiSelect
                          options={receiverCitiesOptions}
                          placeholder=''
                          name='receiverCity'
                          isMulti={false}
                          label="Receiver's City"
                          disabled={true}
                        />
                      ) : (
                        <MultiSelect
                          name='receiverCity'
                          options={receiverCitiesOptions}
                          onInputChange={(value) =>
                            handleCitiesUnChangeDebounced(value, values.receiverCountry, 'receiverCity')
                          }
                          isMulti={false}
                          label="Receiver's City*"
                          placeholder="Select receiver's City"
                        />
                      )}
                      <Input
                        name='receiverAddress'
                        placeholder={values.unknownReceiver ? 'Unknown' : "Receiver's address"}
                        label={values.unknownReceiver ? "Receiver's address" : "Receiver's address*"}
                        disabled={values.unknownReceiver}
                      />
                      <Input
                        name='receiverVatNumber'
                        placeholder={values.unknownReceiver ? 'Unknown' : "Receiver's VAT number"}
                        label={values.unknownReceiver ? "Receiver's VAT number" : "Receiver's VAT number*"}
                        disabled={values.unknownReceiver}
                      />
                      <Input
                        name='receiverPhoneNumber'
                        placeholder={values.unknownReceiver ? 'Unknown' : "Receiver's phone number"}
                        label="Receiver's phone number"
                        disabled={values.unknownReceiver}
                      />
                    </div>
                    <div className='dangerous-goods-checkbox-group'>
                      <div className='checkbox-wrapper'>
                        <input
                          type='checkbox'
                          id='unknownReceiver'
                          name='unknownReceiver'
                          checked={values.unknownReceiver}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setFieldValue('unknownReceiver', isChecked);
                            setFieldValue('receiverName', isChecked ? '' : initialValues.receiverName);
                            setFieldValue('receiverCountry', isChecked ? '' : initialValues.receiverCountry);
                            setFieldValue('receiverCity', isChecked ? '' : initialValues.receiverCity);
                            setFieldValue('receiverAddress', isChecked ? '' : initialValues.receiverAddress);
                            setFieldValue('receiverVatNumber', isChecked ? '' : initialValues.receiverVatNumber);
                            setFieldValue('receiverPhoneNumber', isChecked ? '' : initialValues.receiverPhoneNumber);
                          }}
                        />
                        <label htmlFor='unknownReceiver'>Unknown Receiver</label>
                      </div>
                    </div>
                    <hr />
                    <div className='add-dangerous-good-form__row2'>
                      <Input name='shipperName' placeholder="Shipper's name" label="Shipper's name*" />
                      <MultiSelect
                        options={countryListAllIsoData}
                        isMulti={false}
                        name='shipperCountry'
                        placeholder="Shipper's country"
                        label="Shipper's country*"
                      />
                      <MultiSelect
                        name='shipperCity'
                        options={shipperCitiesOptions}
                        onInputChange={(value) =>
                          handleCitiesUnChangeDebounced(value, values.shipperCountry, 'shipperCity')
                        }
                        isMulti={false}
                        label="Shipper's City*"
                        placeholder="Select shipper's City"
                      />
                      <Input name='shipperAddress' placeholder="Shipper's address" label="Shipper's address*" />
                      <Input name='shipperVatNumber' placeholder="Shipper's VAT number" label="Shipper's VAT number*" />
                      <Input
                        name='shipperPhoneNumber'
                        placeholder="Shipper's phone number"
                        label="Shipper's phone number*"
                      />
                    </div>
                    <div className='add-dangerous-good__form-submission-btns'>
                      <Button
                        className='discard-btn'
                        iconLeft={chevronLeft}
                        onClick={() => {
                          setStepForm(1);
                        }}>
                        Back
                      </Button>
                      {isEditingRow ? (
                        <Button type={'submit'}>Change Information</Button>
                      ) : (
                        <Button iconLeft={plusIcon} type='submit'>
                          Add
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <ConfirmDeleteModal
        title={
          <h3>
            Are you sure you want to{' '}
            <span>
              delete all <br />
              dangerous goods
            </span>{' '}
            from the list?
          </h3>
        }
        show={showConfirmDeleteAllMembersModal}
        onCancelClick={() => setShowConfirmDeleteAllMembersModal(false)}
        onDeleteClick={() => {
          setShowConfirmDeleteAllMembersModal(false);
          dispatch(
            updateDangerousGoods({
              dangerousGoods: [],
            })
          );
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
      <ConfirmDeleteModal
        title={<h3>Are you sure you want to delete this dangerous good?</h3>}
        show={confirmDeleteMemberModalData.showModal}
        onCancelClick={() => {
          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onDeleteClick={() => {
          const newDangerousGoods = [...dangerousGoods.dangerousGoodsList];
          newDangerousGoods.splice(confirmDeleteMemberModalData.index, 1);
          dispatch(
            updateDangerousGoods({
              dangerousGoods: newDangerousGoods,
            })
          );

          setConfirmDeleteMemberModalData({
            index: null,
            showModal: false,
          });
        }}
        onCloseModal={() => setShowConfirmDeleteAllMembersModal(false)}
      />
    </div>
  );
}

export default DangerousGoodsTable;
